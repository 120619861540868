.toc-menu {
  color: var(--toc-font-color);
}

.toc.sidebar .toc-menu {
  margin-right: 0.75rem;
  position: sticky;
  top: var(--toc-top);
}

.toc .toc-menu h3 {
  color: var(--toc-heading-font-color);
  font-size: calc(16 / var(--rem-base) * 1rem);
  font-weight: var(--body-font-weight-bold);
  line-height: 1.3;
  margin: 0 -0.5px;
  padding-bottom: 0.25rem;
}

.toc.sidebar .toc-menu h3 {
  display: flex;
  flex-direction: column;
  height: 2.5rem;
  justify-content: flex-end;
}

.toc .toc-menu ul {
  font-size: calc(15 / var(--rem-base) * 1rem);
  line-height: var(--toc-line-height);
  list-style: none;
  margin: 0;
  padding: 0;
}

.toc.sidebar .toc-menu ul {
  max-height: var(--toc-height);
  overflow-y: auto;
  scrollbar-width: none;
}

.toc .toc-menu ul::-webkit-scrollbar {
  width: 0;
  height: 0;
}

@media screen and (min-width: 1024px) {
  .toc .toc-menu h3 {
    font-size: calc(15 / var(--rem-base) * 1rem);
  }

  .toc .toc-menu ul {
    font-size: calc(13.5 / var(--rem-base) * 1rem);
  }
}

.toc .toc-menu li {
  margin: 0;
}

.toc .toc-menu li[data-level="2"] a {
  padding-left: 1.25rem;
}

.toc .toc-menu li[data-level="3"] a {
  padding-left: 2rem;
}

.toc .toc-menu a {
  color: inherit;
  border-left: 2px solid var(--toc-border-color);
  display: inline-block;
  padding: 0.25rem 0 0.25rem 0.5rem;
  text-decoration: none;
}

.sidebar.toc .toc-menu a {
  display: block;
  outline: none;
}

.toc .toc-menu a:hover {
  color: var(--link-font-color);
}

.toc .toc-menu a.is-active {
  border-left-color: var(--link-font-color);
  color: var(--doc-font-color);
}

.sidebar.toc .toc-menu a:focus {
  background: var(--panel-background);
}

.toc .toc-menu .is-hidden-toc {
  display: none !important;
}
