.live-editor + .payload {
  display: none;
}

.live-editor + .try-btn {
  color: var(--color-white);
  font-size: 85%;
  min-height: 28px;
  border: 0;
  border-radius: 4px;
  padding: 4px 8px;
  margin-top: 0.5rem;
  background-color: var(--color-secondary-green);
}

.editor-window {
  display: block;
}

.editor-window .content {
  background-color: var(--color-white);
  max-width: 90vw;
  max-height: 90vh;
  min-width: 90vw;
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  padding: 1rem;
  margin: auto;
  border-radius: 6px;
}

.editor-window .left {
  width: 50px;
}

.editor-window .right {
  flex: 1;
  overflow-x: hidden;
}

.editor-window .header {
  display: block;
}

.editor-window .editor {
  border: 1px solid var(--color-gray-10);
  position: relative;
  min-height: 150px;
  margin-bottom: 1rem;
}

.editor-window .editor .cm-editor {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  min-height: 150px;
  resize: vertical;
  overflow: hidden;
}

.editor-window .run-btn {
  position: relative;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 1.1rem;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: var(--color-secondary-green);
  color: var(--color-white);
  border: 0;
  cursor: pointer;
}

.editor-window .run-btn::after {
  display: block;
  position: absolute;
  content: "";
  bottom: 50%;
  left: 50%;
  border: 1px solid transparent;
  border-width: 9px 0 9px 16px;
  border-left-color: var(--color-white);
  transform: translate(-6px, 50%);
}

.editor-window .run-btn.loading {
  background-color: var(--color-gray-30);
}

.editor-window .run-btn.loading::before {
  content: "";
  border: 2px solid var(--color-jet-20);
  width: 110%;
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
  border-radius: 50%;
  border-style: dashed;
  animation: rotation 6s infinite linear;
}

.editor-window .output {
  overflow-y: auto;
  resize: vertical;
  height: 150px;
  margin: 0;
  background-color: var(--color-smoke-30);
}

.editor-window .output code {
  height: 100%;
  display: block;
  padding: 0.5rem;
  font-size: 0.88889rem;
}

.editor-window .output .error {
  color: var(--important-color);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
