@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src:
    local("Roboto Regular"),
    local("Roboto-Regular"),
    url(~typeface-roboto/files/roboto-latin-400.woff2) format("woff2"),
    url(~typeface-roboto/files/roboto-latin-400.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src:
    local("Roboto Italic"),
    local("Roboto-Italic"),
    url(~typeface-roboto/files/roboto-latin-400italic.woff2) format("woff2"),
    url(~typeface-roboto/files/roboto-latin-400italic.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src:
    local("Roboto Medium"),
    local("Roboto-Medium"),
    url(~typeface-roboto/files/roboto-latin-500.woff2) format("woff2"),
    url(~typeface-roboto/files/roboto-latin-500.woff) format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src:
    local("Roboto Medium Italic"),
    local("Roboto-MediumItalic"),
    url(~typeface-roboto/files/roboto-latin-500italic.woff2) format("woff2"),
    url(~typeface-roboto/files/roboto-latin-500italic.woff) format("woff");
}
