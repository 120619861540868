.feedback {
  margin-bottom: -1rem;
}

.feedback button {
  display: inline-flex;
  align-items: center;
  color: var(--color-white);
  font-size: 85%;
  min-height: 28px;
  border: 0;
  border-radius: 4px;
  padding: 4px 8px;
  gap: 6px;
  background-color: var(--color-secondary-green);
}

.feedback textarea,
.feedback input {
  display: block;
  min-height: 28px;
  min-width: 50%;
  max-width: 100%;
  padding: 8px;
  margin-bottom: 1rem;
  border: 1px solid var(--color-gray-10);
  border-radius: 4px;
  color: var(--color-jet-50);
  font-family: var(--body-font-family);
  font-size: 85%;
}

.feedback textarea {
  width: 100%;
}

/* hide by height overflow so opacity transition can still take effect */
.feedback .hide {
  opacity: 0 !important;
  height: 0;
  overflow: hidden;
}

.feedback .thumbs {
  display: flex;
  align-items: center;
  gap: 1%;
}

.feedback .thumbs .question {
  font-weight: 500;
  font-size: 105%;
}

.feedback .thumbs button img {
  width: 14px;
  height: 14px;
}

.feedback .feedback-form,
.feedback .confirmation {
  opacity: 1;
  transition: opacity 0.5s;
}

.feedback .feedback-form button {
  font-size: 90%;
}

.feedback .confirmation {
  font-style: italic;
}
