footer.footer {
  background-color: var(--footer-background);
  color: var(--footer-font-color);
  font-size: calc(15 / var(--rem-base) * 1rem);
  line-height: var(--footer-line-height);
  padding: 1.5rem;
}

.footer p {
  margin: 0.5rem 0;
  text-align: center;
}

.footer a {
  color: var(--footer-link-font-color);
}

.footer .footer-social {
  display: flex;
  justify-content: center;
}

.footer .footer-social a {
  margin-left: 2rem;
  margin-right: 2rem;
}

.footer .footer-social a img {
  width: 2rem;
  height: 2rem;
}
