/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 10;
  padding-top: 5vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(3px);
  background-color: rgba(30, 30, 30, 0.8);
}

img.lightbox {
  cursor: pointer;
}
/* Modal Content */
.modal .modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  width: 90%;
  max-height: 90vh;
  cursor: pointer;
}

.modal .modal-content img {
  width: auto;
  height: auto;
  max-width: 90vw;
  max-height: 90vh;
  min-width: 90vw;
  min-height: 90vh;
  display: block;
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;
  z-index: 1;
}
/* The Close Button */
.modal .close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  z-index: 2;
}

.modal .close:hover,
.modal .close:focus {
  color: #999;
  text-decoration: none;
  text-shadow: none;
  cursor: pointer;
}
